<template>
<div>
    <b-card>
        <b-row ref="row">

            <!-- Item Name -->
            <b-col md="4">
                <b-form-group label="Start Date" label-for="item-name">
                    <b-form-datepicker id="startdatepicker-buttons" today-button reset-button close-button v-model="startDate" locale="en" />
                </b-form-group>
            </b-col>

            <!-- Cost -->
            <b-col md="4">
                <b-form-group label="End Date" label-for="item-name">
                    <b-form-datepicker id="enddatepicker-buttons" today-button reset-button close-button v-model="endDate" locale="en" />
                </b-form-group>
            </b-col>

            <!-- Remove Button -->
            <b-col lg="2" md="3" class="mb-50">
                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-2" @click="showTables()">
                    <feather-icon icon="SearchIcon" class="mr-25" />
                    <span>Search</span>
                </b-button>
            </b-col>
            <b-col cols="12">
                <hr>
            </b-col>
        </b-row>
        <b-row>
         <b-spinner small v-show="isLoading" style="margin-left: 30%" />  <small v-show="isLoading"> Please wait as we retrive  messages...</small>
           
        </b-row>
        <b-row ref="inboxList" v-show="showTable">
            <b-col md="2" sm="4" class="my-1">
                <b-form-group class="mb-0">
                    <label class="d-inline-block text-sm-left mr-50">Per page</label>
                    <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
                </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1">
                <b-form-group label="Sort" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="sortBySelect" class="mb-0">
                    <b-input-group size="sm">
                        <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
                            <template v-slot:first>
                                <option value="">
                                    -- none --
                                </option>
                            </template>
                        </b-form-select>
                        <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                            <option :value="false">
                                Asc
                            </option>
                            <option :value="true">
                                Desc
                            </option>
                        </b-form-select>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col md="6" class="my-1">
                <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0" ref="form">
                    <b-input-group size="sm">
                        <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">
                                Clear
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-table :per-page="perPage"
             :items="items" :current-page="currentPage" 
             :fields="fields" :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc" :sort-direction="sortDirection" 
              :filter="filter" :filter-included-fields="filterOn"
               @filtered="onFiltered" responsive>
            </b-table>
            <b-col cols="12">
                 <b-pagination-nav :link-gen="linkGen" :number-of-pages="totalRows/perPage" v-model="currentPage" use-router class="mb-0" />
            </b-col>
        </b-row>

    </b-card>
</div>
</template>

<script>
import {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BCard,
    BTable,
    BFormCheckbox,
    BBadge,
    BInputGroupAppend,
    BFormSelect,
    BInputGroup,
    BPaginationNav,
    BSpinner
} from 'bootstrap-vue'
import {
    heightTransition
} from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import $ from 'jquery'
import moment from "moment";
import store from '@/store'
export default {
    components: {
        BCard,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormGroup,
        BFormInput,
        BFormDatepicker,
        BTable,
        BFormCheckbox,
        BBadge,
        BInputGroupAppend,
        BFormSelect,
        BInputGroup,
        BPaginationNav,
        BSpinner
    },
    directives: {
        Ripple,
    },
    mixins: [heightTransition],
    data() {
        return {
            isLoading: false,
            startDate: "",
            endDate: "",
            perPage: 10,
            totalRows: 0,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            pageOptions: [3, 5, 10, 50],
            showTable: false,
            fields: [{
                    key: 'outbox_id',
                    label: '#ID',
                    sortable: true
                },
                {
                    key: 'msisdn',
                    label: 'Phone Number',
                    sortable: true
                },
                {
                    key: 'description',
                    label: 'Status'
                },
                {
                    key: 'alert_type',
                    label: 'Type'
                },
                {
                    key: 'network',
                    label: 'Network'
                },
                {
                    key: 'short_code',
                    label: 'Sender ID'
                },
                {
                    key: 'message',
                    label: 'Message'
                },
                {
                    key: 'sms_cost',
                    label: 'Cost'
                },
                {
                    key: 'created_at',
                    label: 'Sent On'
                },
            ],
            items: [],
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => ({
                    text: f.label,
                    value: f.key
                }))
        },
    },
    mounted() {
        this.endDate = moment().format("YYYY-MM-DD")
        this.startDate = moment().subtract(7, 'd').format('YYYY-MM-DD');
        this.showTables()
    },
    created() {
        window.addEventListener('resize', this.initTrHeight)
    },
    destroyed() {
        window.removeEventListener('resize', this.initTrHeight)
    },
    watch: {
        currentPage: function (val) {

            this.showTables();

        },
        perPage: function(val){
             this.showTables();
        }
    },
    methods: {
         linkGen(pageNum) {
            return pageNum === 1 ? '?' : `?page=${pageNum}`
        },
        showTables() {
            let vm = this
            if (vm.endDate == "" || vm.startDate == "") {
                this.$swal({
                    title: 'Error!',
                    text: ' You need to select the date',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            } else {
                vm.isLoading = true;
                $.get({
                    url: store.state.rootUrl + 'account/v1/view/bulk_messages',
                    type: "get",
                    async: true,
                    data: {
                        limit: vm.perPage,
                        offset: vm.currentPage,
                        start: vm.startDate,
                        end: vm.endDate
                    },
                    crossDomain: true,
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "X-Authorization-Key": vm.$cookies.get("token"),
                    },
                    success: function (response, status, jQxhr) {
                        vm.isLoading = false
                        if (response.data.code != 200) {

                            vm.errorMessage = response.data.message
                        } else {
                            vm.items = response.data.data.data;
                            vm.showTable = true
                            //vm.initTrHeight()
                            vm.totalRows = response.data.data.total_count
                            
                        }

                    },
                    error: function (jQxhr, status, error) {
                        vm.isLoading = false
                        if (jQxhr.responseJSON.data.code == 401 || jQxhr.responseJSON.data.code == 500 || jQxhr.responseJSON.data.code == 403 || jQxhr.responseJSON.data.code == 404) {
                            vm.$cookies.remove("token");
                            vm.$cookies.remove("clientData");
                            // Redirect to login page
                            vm.$router.push({
                                name: 'login'
                            })
                            vm.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: jQxhr.responseJSON.data.message,
                                    icon: 'EditIcon',
                                    variant: 'failed',
                                },
                            })
                        }

                    }
                });
            }
        },
        onFiltered(filteredItems) {
          //  Trigger pagination to update the number of buttons/pages due to filtering
           this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        initTrHeight() {
            this.trSetHeight(null)
            this.$nextTick(() => {
                this.trSetHeight(this.$refs.form.scrollHeight)
            })
        },
    },
}
</script>
